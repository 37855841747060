import React, { useState } from 'react';
import { useLoaderData } from 'react-router';
import dayjs from 'dayjs';
import {
  BackButton,
  Button,
  Heading,
  Modal,
  PowerBar,
  Toggle,
} from '../../../../components';
import { ScreeningConfig } from '../../../../services/config';
import useTranslate from '../../../../hooks/use-translate';
import HistoryTable from '../../components/history-table';

function getScoreCategory(score, t) {
  if (score < 43) {
    return [0, t('results.category.struggling.title'), 'results.category.struggling.content'];
  }

  if (score >= 43 && score < 57) {
    return [1, t('results.category.languishing.title'), 'results.category.languishing.content'];
  }

  if (score >= 57 && score < 68) {
    return [2, t('results.category.getting_by.title'), 'results.category.getting_by.content'];
  }

  return [3, t('results.category.flourishing.title'), 'results.category.flourishing.content'];
}

function getDate(row) {
  return dayjs(row.timestamp).format('DD/MM/YYYY');
}

function getScore(row, t) {
  const [scoreCategory, label] = getScoreCategory(row?.score?.MHI, t);

  return (
    <div className="flex items-center justify-center">
      <span className="mr-8">{label}</span>
      <div className="hidden lg:block">
        <PowerBar active={scoreCategory} />
      </div>
    </div>
  );
}

function History() {
  const { history, screenings } = useLoaderData();
  const { t } = useTranslate();
  const [selected, setSelected] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);
  const selectedScreening = screenings[selected];
  const selectedConfig = ScreeningConfig[selectedScreening.id];
  const selectedHistory = history[selectedScreening.id];
  const years = [...new Set(selectedHistory.map((item) => new Date(item.timestamp).getFullYear()))];
  const [selectedYear, setSelectedYear] = useState(years[years.length - 1]);
  const handleToggle = (i) => {
    setSelected(i);
  };
  const handleYearToggle = (year) => setSelectedYear(year);
  const toggleItems = screenings.map((screening) => {
    const config = ScreeningConfig[screening.id];

    return (
      <div key={screening.id} className="flex gap-2 lg:gap-3 items-center">
        <img src={config.icon} alt="" className="w-[49px] h-[49px] rounded-full object-cover shrink-0" />
        <div className="flex flex-col">
          <p className="lg:text-xl text-left normal-case">{t(config.title)}</p>
          <p className="hidden lg:block text-base text-left font-normal normal-case">{t(config.history.subtitle)}</p>
        </div>
      </div>
    );
  });

  const filteredHistory = selectedHistory.filter(
    (item) => new Date(item.timestamp).getFullYear() === selectedYear,
  );
  const toggleResultsModal = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  let rowModel;

  switch (selectedScreening.id) {
    case 'dmhi':
      rowModel = [
        getDate,
        (row) => getScore(row, t),
        (row) => {
          const handleClick = (e) => {
            e.preventDefault();

            const record = filteredHistory.find((item) => item.id === row.id);
            setSelectedResult(record);
            toggleResultsModal();
          };

          return (
            <Button type="link" to="" onClick={handleClick} className="text-axa-blue-500 underline">
              {t('history.table.factors', { count: selectedConfig.results.factors.length })}
            </Button>
          );
        },
      ];
      break;
    case 'be-workplace':
      rowModel = [
        getDate,
        (row) => {
          const handleClick = (e) => {
            e.preventDefault();

            const record = filteredHistory.find((item) => item.id === row.id);
            setSelectedResult(record);
            toggleResultsModal();
          };

          return (
            <Button type="link" to="" onClick={handleClick} className="text-axa-blue-500 underline">
              {t('history.table.factors', { count: selectedConfig.results.factors.length })}
            </Button>
          );
        },
      ];
      break;
    default:
  }

  return (
    <div className="container mx-auto pt-8 md:pt-16 md:pb-16 px-4">
      <div className="flex flex-col lg:flex-row items-start lg:items-center gap-6">
        <BackButton variant="button" />
        <Heading variant="h1" className="text-center">{t('history.title')}</Heading>
      </div>
      <Modal open={isOpen} onClose={toggleResultsModal}>
        <div className="text-center">
          <div className="flex flex-col gap-6 mt-4 items-center">
            <div className="flex flex-col gap-2">
              <Heading variant="h2">{t(selectedConfig.title)}</Heading>
              <Heading variant="h3">{dayjs(selectedResult?.timestamp).format('DD/MM/YYYY')}</Heading>
              {selectedScreening.id === 'dmhi' ? getScore(selectedScreening?.score?.MHI, t) : null}
            </div>
            <div className="flex flex-wrap items-center justify-center gap-6 mt-6">
              {selectedConfig.results.factors.map((factor) => {
                let score = selectedResult?.score?.[factor.key];
                const isPercentage = selectedConfig.results.percentage;
                const bounds = factor.bounds ?? selectedConfig.results.bounds;

                if (isPercentage) {
                  score *= selectedConfig.results.percentageFactor;
                }

                const bound = bounds.findIndex(([x, y]) => score >= x && score <= y);

                return (
                  <div className="flex flex-grow items-center gap-4 w-full max-w-[400px]">
                    <div className="shrink-0">
                      <img src={factor.icon} alt="" className="w-[61px] h-[61px] object-cover rounded-full" />
                    </div>
                    <div className="flex flex-col gap-2 w-full items-start">
                      <Heading variant="h3">
                        {t(factor.title)}
                      </Heading>
                      <div className="flex gap-2 items-center w-full max-w-[120px]">
                        <p className="font-semibold text-axa-blue-100">{score}{isPercentage ? '%' : ''}</p>
                        <PowerBar active={bound} score={score} variant="mini" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Modal>
      <div className="bg-white w-full mx-4 mt-6">
        <div className="p-4">
          <Toggle
            items={toggleItems}
            active={selected}
            onChange={handleToggle}
          />
        </div>
        {years.length > 1 ? (
          <div className="flex gap-2 mx-4">
            {years.map((year) => {
              const variant = selectedYear === year ? 'primary' : 'outline-primary';

              return (
                <Button key={year} type="button" variant={variant} className="w-full" onClick={() => handleYearToggle(year)}>
                  {year}
                </Button>
              );
            })}
          </div>
        ) : null}
        <div className="mt-4">
          <HistoryTable
            columns={selectedConfig.history.columns.map((col) => t(col))}
            rows={filteredHistory}
            rowModel={rowModel}
          />
        </div>
      </div>
    </div>
  );
}

export { default as historyLoader } from './loader';
export default History;
