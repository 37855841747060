import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BackButton,
  Button,
  ButtonGroup,
  Toggle,
} from '../../../../components';
import useReport from '../../../../hooks/use-report';
import Library from '../../components/library';
import Support from '../../components/support';
import BESupport from '../../../results/components/be-support';

function Recommendations() {
  const { report } = useReport();
  const [selected, setSelected] = useState(0);
  const { t } = useTranslation();
  let support;

  switch (import.meta.env.VITE_DMHI_ENTITY) {
    case 'be':
      support = <div className="flex flex-col gap-5"><BESupport /></div>;
      break;
    default:
      support = <Support />;
  }

  const handleToggle = (i) => {
    report({
      action: `toggle[${i}]`,
    });
    setSelected(i);
  };

  return (
    <div className="w-full mx-auto relative">
      <div className="w-full h-32 bg-axa-blue-50 relative md:h-[276px]">
        <div className="bg-[url('/bg-hero-recommendations-1.png')] bg-cover bg-no-repeat w-[280px] h-[200px] md:h-[276px] absolute right-0 top-[-25px] ml-[-140px] md:ml-0 md:left-0 md:w-[500px]" />
      </div>
      <div className="container mx-auto px-4 flex flex-col mt-[80px] md:max-w-[756px] lg:max-w-[957px] lg:pb-[100px] md:mt-[-100px]">
        <div className="mx-auto max-w-[736px] rounded md:bg-white md:px-8 md:py-6 z-10">
          <h1 className="text-2xl text-gray-900 font-bold">{t('recommendations.title')}</h1>
          <div className="flex flex-col gap-3 text-gray-700 mt-3">
            <p>{t('recommendations.content.1')}</p>
            <p>{t('recommendations.content.2')}</p>
            <p>{t('recommendations.content.3')}</p>
            <p>{t('recommendations.content.4')}</p>
            <p>{t('recommendations.content.5')}</p>
          </div>
        </div>
        <div className="mt-8">
          <div className="flex justify-center mx-auto max-w-[300px]">
            <Toggle
              items={[t('recommendations.guides'), t('recommendations.get_support')]}
              active={selected}
              onChange={handleToggle}
            />
          </div>
          <div className="mt-8 lg:mt-12">
            {selected === 0 ? <Library /> : support}
          </div>
          <div className="mt-24">
            <ButtonGroup row className="md:!w-96 !justify-between">
              <BackButton />
              <Button variant="default" type="link" to="/" id="exit" className="shrink-0">{t('exit')}</Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Recommendations;
