import React from 'react';
import { createBrowserRouter } from 'react-router';
import { RouterProvider } from 'react-router/dom';
import Root, { rootLoader } from './root';
import Callback, { callbackLoader } from './callback/routes/_index';
import Landing, { landingLoader } from './landing';
import Consent from './consent/routes/_index';
import Information from './information/routes/_index';
import InformationBE from './information/routes/_index_be';
import Safeguarding from './safeguarding/routes/_index';
import SafeguardingBE from './safeguarding/routes/_index_be';
import ScreeningIndex, { screeningLoader } from './screening/routes/_index';
import sharedScreeningLoader from './_loaders/screening';
import ScreeningIntro from './screening/routes/intro';
import ScreeningSection from './screening/routes/section';
import ScreeningQuestion from './screening/routes/question';
import ScreeningComplete, { screeningCompleteAction } from './screening/routes/complete';
import Results from './results/routes/_index';
import ResultsSubscore from './results/routes/subscore';
import Recommendations from './recommendations/routes/_index';
import RecommendationsGuide from './recommendations/routes/guide';
import History, { historyLoader } from './history/routes/_index';
import TermsOfUse from './terms-of-use/routes/_index';

const entity = import.meta.env.VITE_DMHI_ENTITY;
let router;

switch (entity) {
  case 'be': {
    router = createBrowserRouter([
      {
        path: '/',
        id: 'root',
        children: [
          {
            element: <Root />,
            loader: rootLoader,
            children: [
              {
                path: '/:lang?',
                index: true,
                element: <Landing />,
                loader: landingLoader,
                handle: {
                  key: () => 'local_landing',
                },
              },
              {
                path: '/:lang?/safeguarding',
                element: <SafeguardingBE />,
                handle: {
                  key: () => 'safeguarding',
                },
              },
              {
                path: '/:lang?/assessment/:assessmentId',
                element: <ScreeningIntro />,
                loader: screeningLoader,
                handle: {
                  key: () => 'start_assessment',
                },
              },
              {
                path: '/:lang?/assessment/:assessmentId/consent',
                element: <Consent />,
                loader: screeningLoader,
                handle: {
                  key: () => 'consent',
                },
              },
              {
                path: '/:lang?/information',
                element: <InformationBE />,
                handle: {
                  key: () => 'information_notice',
                },
              },
              {
                path: '/:lang?/terms-of-use',
                element: <TermsOfUse />,
                handle: {
                  key: () => 'terms_of_use',
                },
              },
              {
                path: '/:lang?/assessment/:assessmentId/start',
                element: <ScreeningIndex />,
                loader: sharedScreeningLoader,
                handle: {
                  key: () => 'start_assessment',
                },
              },
              {
                path: '/:lang?/assessment/:assessmentId/start/section/:sectionIndex',
                element: <ScreeningSection />,
                loader: sharedScreeningLoader,
                handle: {
                  key: ({ sectionIndex }) => `section_${sectionIndex}`,
                },
              },
              {
                path: '/:lang?/assessment/:assessmentId/start/section/:sectionIndex/q/:questionIndex',
                element: <ScreeningQuestion />,
                loader: sharedScreeningLoader,
                handle: {
                  key: ({ sectionIndex, questionIndex }) => `section_${sectionIndex}_question_${questionIndex}`,
                },
              },
              {
                path: '/:lang?/assessment/:assessmentId/start/complete',
                element: <ScreeningComplete />,
                loader: sharedScreeningLoader,
                action: screeningCompleteAction,
                handle: {
                  key: () => 'complete_assessment',
                },
              },
              {
                path: '/:lang?/assessment/:assessmentId/results',
                element: <Results />,
                handle: {
                  key: () => 'results',
                },
              },
              {
                path: '/:lang?/assessment/:assessmentId/results/:subscore',
                element: <ResultsSubscore />,
                handle: {
                  key: ({ subscore }) => `results_${subscore}`,
                },
              },
              {
                path: '/:lang?/assessment/:assessmentId/results/recommendations',
                element: <Recommendations />,
                handle: {
                  key: () => 'recommendations',
                },
              },
              {
                path: '/:lang?/assessment/:assessmentId/results/recommendations/guide/:guide',
                element: <RecommendationsGuide />,
                handle: {
                  key: ({ guide }) => `guide_${guide}`,
                },
              },
              {
                path: '/:lang?/history',
                element: <History />,
                loader: historyLoader,
              },
            ],
          },
          {
            path: '/callback',
            element: <Callback />,
            loader: callbackLoader,
          },
        ],
      },
    ]);

    break;
  }
  default:
    router = createBrowserRouter([
      {
        path: '/',
        id: 'root',
        children: [
          {
            element: <Root />,
            loader: rootLoader,
            children: [
              {
                path: '/:lang?',
                index: true,
                element: <Landing />,
                loader: landingLoader,
                handle: {
                  key: () => 'global_landing',
                },
              },
              {
                path: '/:lang?/consent',
                element: <Consent />,
                loader: screeningLoader,
                handle: {
                  key: () => 'consent',
                },
              },
              {
                path: '/:lang?/information',
                element: <Information />,
                handle: {
                  key: () => 'information_notice',
                },
              },
              {
                path: '/:lang?/safeguarding',
                element: <Safeguarding />,
                handle: {
                  key: () => 'safeguarding',
                },
              },
              {
                path: '/:lang?/assessment',
                element: <ScreeningIndex />,
                loader: screeningLoader,
                handle: {
                  key: () => 'start_assessment',
                },
              },
              {
                path: '/:lang?/assessment/section/:sectionIndex',
                element: <ScreeningSection />,
                loader: sharedScreeningLoader,
                handle: {
                  key: ({ sectionIndex }) => `section_${sectionIndex}`,
                },
              },
              {
                path: '/:lang?/assessment/section/:sectionIndex/q/:questionIndex',
                element: <ScreeningQuestion />,
                loader: sharedScreeningLoader,
                handle: {
                  key: ({ sectionIndex, questionIndex }) => `section_${sectionIndex}_question_${questionIndex}`,
                },
              },
              {
                path: '/:lang?/assessment/complete',
                element: <ScreeningComplete />,
                loader: sharedScreeningLoader,
                action: screeningCompleteAction,
                handle: {
                  key: () => 'complete_assessment',
                },
              },
              {
                path: '/:lang?/results',
                element: <Results />,
                handle: {
                  key: () => 'results',
                },
              },
              {
                path: '/:lang?/results/:subscore',
                element: <ResultsSubscore />,
                handle: {
                  key: ({ subscore }) => `results_${subscore}`,
                },
              },
              {
                path: '/:lang?/results/recommendations',
                element: <Recommendations />,
                handle: {
                  key: () => 'recommendations',
                },
              },
              {
                path: '/:lang?/results/recommendations/guide/:guide',
                element: <RecommendationsGuide />,
                handle: {
                  key: ({ guide }) => `guide_${guide}`,
                },
              },
            ],
          },
          {
            path: '/callback',
            element: <Callback />,
            loader: callbackLoader,
          },
        ],
      },
    ]);
}

function Router() {
  return (
    <RouterProvider router={router} />
  );
}

export default Router;
