import React from 'react';
import { useNavigate } from 'react-router';
import classnames from 'classnames';
import Button from '../button';
import IconNavigateBefore from '../../assets/icon-navigate-before.svg?react';
import useTranslate from '../../hooks/use-translate';

const commonStyles = {
  button: 'flex items-center gap-4',
  icon: 'inline',
};

const variantStyles = {
  button: {
    button: 'bg-axa-blue-500 text-white',
    icon: 'fill-white',
  },
  link: {
    button: 'text-axa-blue-500',
    icon: 'fill-axa-blue-500',
  },
};

function BackButton({ className, variant = 'link', ...props }) {
  const styles = variantStyles[variant];
  const buttonClasses = classnames(commonStyles.button, styles.button, className);
  const iconClasses = classnames(commonStyles.icon, styles.icon);
  const navigate = useNavigate();
  const { t } = useTranslate();

  return (
    <Button
      variant="default"
      type="button"
      onClick={(e) => { e.preventDefault(); navigate(-1); }}
      size="sm"
      className={buttonClasses}
      tabIndex="-1"
      {...props}
    >
      <IconNavigateBefore className={iconClasses} />
      {t('back')}
    </Button>
  );
}

export default BackButton;
