import { redirect } from 'react-router';
import merge from 'lodash.merge';
import Config from '../../screening/config';
import useStore from '../../../store';
import getPath from '../../../utils/path';

// Common loader for all routes related to a screening.
async function loader({ params, request }) {
  const {
    assessmentId,
    lang,
    sectionIndex,
    questionIndex,
  } = params;
  const { screenings, responses } = useStore.getState();
  let Screening;
  let options;

  if (screenings === null) {
    return redirect(getPath(lang, '/?error=invalid_request_data'));
  }

  if (assessmentId === undefined || assessmentId === null || assessmentId === 'dmhi') {
    ({ options } = screenings?.find((screening) => screening.id === 'dmhi') ?? {});
    Screening = structuredClone(Config.Screening.Global);
  }

  if (Config.Screening[assessmentId] !== undefined) {
    ({ options } = screenings?.find((screening) => screening.id === assessmentId) ?? {});
    Screening = structuredClone(Config.Screening[assessmentId]);
  }

  if (options?.demographics) {
    Screening.Sections.unshift(Config.Screening.Demographics);
  }

  if (Screening !== undefined) {
    const complete = new URL(request.url).pathname.endsWith('/complete');

    // If we're mid-way through a screening we'll have a section and/or question
    // index and can check whether or not we have a valid set of responses for
    // where we are in the screening.
    if (complete || sectionIndex !== undefined) {
      const si = complete ? Screening.Sections.length : parseInt(sectionIndex, 10);
      const qi = complete
        ? Screening.Sections[Screening.Sections.length - 1].questions.length
        : parseInt(questionIndex, 10);

      for (let i = 0; i < si; i++) {
        const section = Screening.Sections[i];
        const { questions } = section;
        const limit = i < si - 1 ? questions.length : qi - 1;

        for (let j = 0; j < limit; j++) {
          const question = questions[j];
          const response = responses[question.promptId];

          if (response === undefined) {
            return redirect(getPath(lang, '/?error=invalid_request_data'));
          }
        }
      }
    }

    // Tenants can specify specific overrides to the global DMHI screening
    // config.
    const overrides = Screening?.overrides?.[import.meta.env.VITE_DMHI_ENTITY];

    if (overrides !== undefined) {
      merge(Screening, overrides); // In-place deep object merge.
    }

    return {
      Screening,
      options,
    };
  }

  throw new Response('Not Found', { status: 404 });
}

export default loader;
