import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Heading } from '../../../../components';
import getPath from '../../../../utils/path';
import IconNavigateBefore from '../../../../assets/icon-navigate-before.svg?react';
import Summary from '../../components/summary';
import ComponentCard from '../../components/component-card';
import useResults from '../../hooks/use-results';

function getScoreCategory(score, t) {
  if (score < 43) {
    return [0, t('results.category.struggling.content')];
  }

  if (score >= 43 && score < 57) {
    return [1, t('results.category.languishing.content')];
  }

  if (score >= 57 && score < 68) {
    return [2, t('results.category.getting_by.content')];
  }

  return [3, t('results.category.flourishing.content')];
}

function getScoreDesc(score, type, t) {
  if (score < 20) {
    return t(`results.${type}.category.1.content`);
  }

  if (score >= 20 && score < 40) {
    return t(`results.${type}.category.2.content`);
  }

  if (score >= 40 && score < 60) {
    return t(`results.${type}.category.3.content`);
  }

  if (score >= 60 && score < 80) {
    return t(`results.${type}.category.4.content`);
  }

  return t(`results.${type}.category.5.content`);
}

function getComponentDesc(score, subscore, type, t) {
  switch (type) {
    case 'quality_connections':
    case 'self_worth':
    case 'self_care':
      if (score < 60) {
        return t(`results.${subscore}.component.${type}.category.1.content`);
      }

      if (score >= 60 && score < 80) {
        return t(`results.${subscore}.component.${type}.category.2.content`);
      }

      return t(`results.${subscore}.component.${type}.category.3.content`);
    case 'challenge_response':
      if (score < 48) {
        return t(`results.${subscore}.component.${type}.category.1.content`);
      }

      if (score >= 48 && score < 72) {
        return t(`results.${subscore}.component.${type}.category.2.content`);
      }

      return t(`results.${subscore}.component.${type}.category.3.content`);
    case 'stress':
    case 'feeling_anxious':
    case 'feeling_low':
    case 'my_control':
    case 'resilience':
    case 'meaning':
    case 'financial_wellbeing':
      if (score < 40) {
        return t(`results.${subscore}.component.${type}.category.1.content`);
      }

      if (score >= 40 && score < 60) {
        return t(`results.${subscore}.component.${type}.category.2.content`);
      }

      if (score >= 60 && score < 80) {
        return t(`results.${subscore}.component.${type}.category.3.content`);
      }

      return t(`results.${subscore}.component.${type}.category.4.content`);

    case 'life_satisfaction':
    case 'positive_emotions':
    case 'optimism':
      if (score < 20) {
        return t(`results.${subscore}.component.${type}.category.1.content`);
      }

      if (score >= 20 && score < 40) {
        return t(`results.${subscore}.component.${type}.category.2.content`);
      }

      if (score >= 40 && score < 60) {
        return t(`results.${subscore}.component.${type}.category.3.content`);
      }

      if (score >= 60 && score < 80) {
        return t(`results.${subscore}.component.${type}.category.4.content`);
      }

      return t(`results.${subscore}.component.${type}.category.5.content`);
    case 'me_time':
    case 'sleep':
      if (score < 20) {
        return t(`results.${subscore}.component.${type}.category.1.content`);
      }

      if (score >= 20 && score < 40) {
        return t(`results.${subscore}.component.${type}.category.2.content`);
      }

      if (score >= 40 && score < 70) {
        return t(`results.${subscore}.component.${type}.category.3.content`);
      }

      return t(`results.${subscore}.component.${type}.category.4.content`);
    default:
  }

  return null;
}

const scoreComponents = {
  csom: [
    'stress',
    'life_satisfaction',
    'positive_emotions',
    'feeling_anxious',
    'feeling_low',
  ],
  sb: [
    'quality_connections',
    'my_control',
    'resilience',
    'optimism',
    'self_worth',
    'meaning',
    'challenge_response',
  ],
  ls: [
    'self_care',
    'sleep',
    'me_time',
    'financial_wellbeing',
  ],
};

const componentTypes = {
  stress: 'SCB_10',
  life_satisfaction: 'GRID_QA18b:_1',
  positive_emotions: 'SCC_3',
  feeling_anxious: 'SCB_9',
  feeling_low: 'SCB_8',
  quality_connections: 'QC',
  my_control: 'MYCTRL',
  resilience: 'RSLNC',
  optimism: 'OPTMSM',
  self_worth: 'SLFWRTH',
  meaning: 'MNNG',
  challenge_response: 'A18_19',
  self_care: 'SLFCR',
  sleep: 'SLP',
  me_time: 'MTM',
  financial_wellbeing: 'FS',
};

const resultTypes = {
  csom: 'CSOM',
  sb: 'SB',
  ls: 'SCB_2',
};

const heroes = {
  csom: <div className="bg-[url('/bg-hero-csom-1.png')] bg-cover bg-no-repeat bg-center w-full h-[200px] z-[-1] md:h-[509px]" />,
  sb: <div className="bg-[url('/bg-hero-sb-1.png')] bg-cover bg-no-repeat bg-center w-full h-[200px] z-[-1] md:h-[509px]" />,
  ls: <div className="bg-[url('/bg-hero-ls-1.png')] bg-cover bg-no-repeat w-full h-[200px] z-[-1] md:h-[509px]" />,
};

function ResultsSubscore() {
  const navigate = useNavigate();
  const { results } = useResults();
  const { subscore, lang, assessmentId } = useParams();
  const { t } = useTranslation();
  const result = results?.[resultTypes[subscore]];
  const [scoreCategory] = getScoreCategory(result, t);
  const desc = getScoreDesc(result, subscore, t);
  const components = scoreComponents[subscore];
  const hero = heroes[subscore];
  const cta = assessmentId !== undefined
    ? getPath(lang, `/assessment/${assessmentId}/results/recommendations`)
    : getPath(lang, '/results/recommendations');


  const supportScores = components.filter((component) => {
    const score = results?.[componentTypes[component]];

    return score <= 60;
  });

  const goodScores = components.filter((component) => {
    const score = results?.[componentTypes[component]];

    return score > 60;
  });

  return (
    <div className="w-full mx-auto relative">
      <div className="w-full md:bg-gray-200 md:h-[370px]">
        {hero}
      </div>
      <div className="container mx-auto px-4 flex flex-col mt-[-100px] md:max-w-[756px] lg:max-w-[957px] lg:pb-[200px] md:mt-[-100px]">
        <div className="mx-auto max-w-[736px] min-h-[220px]">
          <Summary
            title={t(`screening.global.section.${subscore}.title`)}
            score={result}
            scoreCategory={scoreCategory}
            desc={desc}
            explain={t(`results.${subscore}.explain.content`)}
            cta={t(`results.${subscore}.explain.cta`)}
          />
        </div>
        {goodScores.length > 0 && (
          <>
            <Heading variant="h2" className="mt-12">{t('results.good')}</Heading>
            <div className="flex flex-col gap-4 mt-4 flex-wrap md:flex-row">
              {goodScores.map((component) => {
                const score = results?.[componentTypes[component]];
                const explain = getComponentDesc(score, subscore, component, t);

                return (
                  <ComponentCard
                    key={component}
                    id={component}
                    title={t(`results.${subscore}.component.${component}.title`)}
                    cta={t('results.component.explore')}
                    explain={explain}
                    score={score}
                  />
                );
              })}
            </div>
          </>
        )}
        {supportScores.length > 0 && (
          <>
            <Heading variant="h2" className="mt-12">{t('results.support')}</Heading>
            <div className="flex flex-col gap-4 mt-4 flex-wrap md:flex-row">
              {supportScores.map((component) => {
                const score = results?.[componentTypes[component]];
                const explain = getComponentDesc(score, subscore, component, t);

                return (
                  <ComponentCard
                    key={component}
                    id={component}
                    title={t(`results.${subscore}.component.${component}.title`)}
                    cta={t('results.component.explore')}
                    explain={explain}
                    score={score}
                  />
                );
              })}
            </div>
          </>
        )}
        <div className="flex items-center justify-center mt-12 gap-4">
          <Button
            variant="default"
            type="button"
            onClick={(e) => { e.preventDefault(); navigate(-1); }}
            size="sm"
            className="flex items-center gap-4 text-axa-blue-500"
            tabIndex="-1"
            id="back"
          >
            <IconNavigateBefore className="inline fill-axa-blue-500" />
            {t('back')}
          </Button>
          <Button type="link" to={cta} variant="primary" id="what_you_can_do_next" className="min-w-52">
            {t('results.do_next')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ResultsSubscore;
