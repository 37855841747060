const Config = {
  Screening: {
    Demographics: {
      title: 'screening.demographics.title',
      background: '/bg-hero-work-management-1.png',
      backgroundStyles: 'md:bg-top lg:bg-center',
      id: 'demographics',
      questions: [
        {
          id: 'DEM_1',
          promptId: 'DEM_1_1',
          question: 'screening.demographics.question.DEM_1',
          responses: [
            {
              id: 'DEM_1_1:_1',
              content: 'screening.demographics.response.DEM_1_1_1',
            },
            {
              id: 'DEM_1_1:_2',
              content: 'screening.demographics.response.DEM_1_1_2',
            },
            {
              id: 'DEM_1_1:_3',
              content: 'screening.demographics.response.DEM_1_1_3',
            },
            {
              id: 'DEM_1_1:_4',
              content: 'screening.demographics.response.DEM_1_1_4',
            },
            {
              id: 'DEM_1_1:_5',
              content: 'screening.demographics.response.DEM_1_1_5',
            },
          ],
        },
        {
          id: 'DEM_2',
          promptId: 'DEM_2_1',
          question: 'screening.demographics.question.DEM_2',
          responses: [
            {
              id: 'DEM_2_1:_1',
              content: 'screening.demographics.response.DEM_2_1_1',
            },
            {
              id: 'DEM_2_1:_2',
              content: 'screening.demographics.response.DEM_2_1_2',
            },
            {
              id: 'DEM_2_1:_3',
              content: 'screening.demographics.response.DEM_2_1_3',
            },
          ],
        },
        {
          id: 'DEM_3',
          promptId: 'DEM_3_1',
          question: 'screening.demographics.question.DEM_3',
          responses: [
            {
              id: 'DEM_3_1:_1',
              content: 'screening.demographics.response.DEM_3_1_1',
            },
            {
              id: 'DEM_3_1:_2',
              content: 'screening.demographics.response.DEM_3_1_2',
            },
            {
              id: 'DEM_3_1:_3',
              content: 'screening.demographics.response.DEM_3_1_3',
            },
          ],
        },
      ],
    },
    Global: {
      overrides: {
        be: {
          intro: {
            background: '/bg-hero-work-security-1.png',
            title: 'be.screening.dmhi.intro.title',
            content: 'be.screening.dmhi.intro.content',
          },
          subtitle: 'be.screening.dmhi.subtitle',
        },
      },
      title: 'screening.global.title',
      subtitle: 'screening.global.subtitle',
      intro: {
        background: '/bg-hero-onboarding-1.png',
        title: 'welcome.title',
        content: 'welcome.intro',
        cta: 'welcome.cta',
      },
      Sections: [
        {
          title: 'screening.global.section.csom.title',
          content: 'screening.global.section.csom.intro',
          background: '/bg-hero-csom-1.png',
          backgroundStyles: 'md:bg-top lg:bg-center',
          id: 'csom',
          questions: [
            {
              id: 'GRID_QA18b',
              promptId: 'GRID_QA18b:_1',
              question: 'screening.global.question.GRID_QA18b',
              prompt: 'screening.global.question.GRID_QA18b_1',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'GRID_QA18b.QA18b:_1',
                  content: 'screening.global.response.GRID_QA18b.QA18b_1',
                },
                {
                  id: 'GRID_QA18b.QA18b:_2',
                  content: 'screening.global.response.GRID_QA18b.QA18b_2',
                },
                {
                  id: 'GRID_QA18b.QA18b:_3',
                  content: 'screening.global.response.GRID_QA18b.QA18b_3',
                },
                {
                  id: 'GRID_QA18b.QA18b:_4',
                  content: 'screening.global.response.GRID_QA18b.QA18b_4',
                },
                {
                  id: 'GRID_QA18b.QA18b:_5',
                  content: 'screening.global.response.GRID_QA18b.QA18b_5',
                },
              ],
            },
            {
              id: 'GRID_QA15f',
              promptId: 'GRID_QA15f:_1',
              question: 'screening.global.question.GRID_QA15f',
              prompt: 'screening.global.question.GRID_QA15f_1',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'GRID_QA15f.QA15f:_1',
                  content: 'screening.global.response.GRID_QA15f.QA15f_1',
                },
                {
                  id: 'GRID_QA15f.QA15f:_2',
                  content: 'screening.global.response.GRID_QA15f.QA15f_2',
                },
                {
                  id: 'GRID_QA15f.QA15f:_3',
                  content: 'screening.global.response.GRID_QA15f.QA15f_3',
                },
                {
                  id: 'GRID_QA15f.QA15f:_4',
                  content: 'screening.global.response.GRID_QA15f.QA15f_4',
                },
                {
                  id: 'GRID_QA15f.QA15f:_5',
                  content: 'screening.global.response.GRID_QA15f.QA15f_5',
                },
              ],
            },
            {
              id: 'GRID_QA15f',
              promptId: 'GRID_QA15f:_2',
              question: 'screening.global.question.GRID_QA15f',
              prompt: 'screening.global.question.GRID_QA15f_2',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'GRID_QA15f.QA15f:_1',
                  content: 'screening.global.response.GRID_QA15f.QA15f_1',
                },
                {
                  id: 'GRID_QA15f.QA15f:_2',
                  content: 'screening.global.response.GRID_QA15f.QA15f_2',
                },
                {
                  id: 'GRID_QA15f.QA15f:_3',
                  content: 'screening.global.response.GRID_QA15f.QA15f_3',
                },
                {
                  id: 'GRID_QA15f.QA15f:_4',
                  content: 'screening.global.response.GRID_QA15f.QA15f_4',
                },
                {
                  id: 'GRID_QA15f.QA15f:_5',
                  content: 'screening.global.response.GRID_QA15f.QA15f_5',
                },
              ],
            },
            {
              id: 'GRID_QA15f',
              promptId: 'GRID_QA15f:_3',
              question: 'screening.global.question.GRID_QA15f',
              prompt: 'screening.global.question.GRID_QA15f_3',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'GRID_QA15f.QA15f:_1',
                  content: 'screening.global.response.GRID_QA15f.QA15f_1',
                },
                {
                  id: 'GRID_QA15f.QA15f:_2',
                  content: 'screening.global.response.GRID_QA15f.QA15f_2',
                },
                {
                  id: 'GRID_QA15f.QA15f:_3',
                  content: 'screening.global.response.GRID_QA15f.QA15f_3',
                },
                {
                  id: 'GRID_QA15f.QA15f:_4',
                  content: 'screening.global.response.GRID_QA15f.QA15f_4',
                },
                {
                  id: 'GRID_QA15f.QA15f:_5',
                  content: 'screening.global.response.GRID_QA15f.QA15f_5',
                },
              ],
            },
            {
              id: 'A15',
              promptId: 'A15:_1',
              question: 'screening.global.question.A15',
              prompt: 'screening.global.question.A15_1',
              timeframe: 'screening.global.question.timeframe.week',
              timeframeModal: 'timeframe.content.week',
              responses: [
                {
                  id: 'A15._scale:_1',
                  content: 'screening.global.response.A15._scale_1',
                },
                {
                  id: 'A15._scale:_2',
                  content: 'screening.global.response.A15._scale_2',
                },
                {
                  id: 'A15._scale:_3',
                  content: 'screening.global.response.A15._scale_3',
                },
                {
                  id: 'A15._scale:_4',
                  content: 'screening.global.response.A15._scale_4',
                },
              ],
            },
            {
              id: 'A15',
              promptId: 'A15:_2',
              question: 'screening.global.question.A15',
              prompt: 'screening.global.question.A15_2',
              timeframe: 'screening.global.question.timeframe.week',
              responses: [
                {
                  id: 'A15._scale:_1',
                  content: 'screening.global.response.A15._scale_1',
                },
                {
                  id: 'A15._scale:_2',
                  content: 'screening.global.response.A15._scale_2',
                },
                {
                  id: 'A15._scale:_3',
                  content: 'screening.global.response.A15._scale_3',
                },
                {
                  id: 'A15._scale:_4',
                  content: 'screening.global.response.A15._scale_4',
                },
              ],
            },
            {
              id: 'A15',
              promptId: 'A15:_3',
              question: 'screening.global.question.A15',
              prompt: 'screening.global.question.A15_3',
              timeframe: 'screening.global.question.timeframe.week',
              responses: [
                {
                  id: 'A15._scale:_1',
                  content: 'screening.global.response.A15._scale_1',
                },
                {
                  id: 'A15._scale:_2',
                  content: 'screening.global.response.A15._scale_2',
                },
                {
                  id: 'A15._scale:_3',
                  content: 'screening.global.response.A15._scale_3',
                },
                {
                  id: 'A15._scale:_4',
                  content: 'screening.global.response.A15._scale_4',
                },
              ],
            },
            {
              id: 'A15',
              promptId: 'A15:_4',
              question: 'screening.global.question.A15',
              prompt: 'screening.global.question.A15_4',
              timeframe: 'screening.global.question.timeframe.week',
              responses: [
                {
                  id: 'A15._scale:_1',
                  content: 'screening.global.response.A15._scale_1',
                },
                {
                  id: 'A15._scale:_2',
                  content: 'screening.global.response.A15._scale_2',
                },
                {
                  id: 'A15._scale:_3',
                  content: 'screening.global.response.A15._scale_3',
                },
                {
                  id: 'A15._scale:_4',
                  content: 'screening.global.response.A15._scale_4',
                },
              ],
            },
            {
              id: 'A15',
              promptId: 'A15:_5',
              question: 'screening.global.question.A15',
              prompt: 'screening.global.question.A15_5',
              timeframe: 'screening.global.question.timeframe.week',
              responses: [
                {
                  id: 'A15._scale:_1',
                  content: 'screening.global.response.A15._scale_1',
                },
                {
                  id: 'A15._scale:_2',
                  content: 'screening.global.response.A15._scale_2',
                },
                {
                  id: 'A15._scale:_3',
                  content: 'screening.global.response.A15._scale_3',
                },
                {
                  id: 'A15._scale:_4',
                  content: 'screening.global.response.A15._scale_4',
                },
              ],
            },
            {
              id: 'A15',
              promptId: 'A15:_6',
              question: 'screening.global.question.A15',
              prompt: 'screening.global.question.A15_6',
              timeframe: 'screening.global.question.timeframe.week',
              responses: [
                {
                  id: 'A15._scale:_1',
                  content: 'screening.global.response.A15._scale_1',
                },
                {
                  id: 'A15._scale:_2',
                  content: 'screening.global.response.A15._scale_2',
                },
                {
                  id: 'A15._scale:_3',
                  content: 'screening.global.response.A15._scale_3',
                },
                {
                  id: 'A15._scale:_4',
                  content: 'screening.global.response.A15._scale_4',
                },
              ],
            },
            {
              id: 'A15',
              promptId: 'A15:_7',
              question: 'screening.global.question.A15',
              prompt: 'screening.global.question.A15_7',
              timeframe: 'screening.global.question.timeframe.week',
              responses: [
                {
                  id: 'A15._scale:_1',
                  content: 'screening.global.response.A15._scale_1',
                },
                {
                  id: 'A15._scale:_2',
                  content: 'screening.global.response.A15._scale_2',
                },
                {
                  id: 'A15._scale:_3',
                  content: 'screening.global.response.A15._scale_3',
                },
                {
                  id: 'A15._scale:_4',
                  content: 'screening.global.response.A15._scale_4',
                },
              ],
            },
            {
              id: 'A15',
              promptId: 'A15:_8',
              question: 'screening.global.question.A15',
              prompt: 'screening.global.question.A15_8',
              timeframe: 'screening.global.question.timeframe.week',
              responses: [
                {
                  id: 'A15._scale:_1',
                  content: 'screening.global.response.A15._scale_1',
                },
                {
                  id: 'A15._scale:_2',
                  content: 'screening.global.response.A15._scale_2',
                },
                {
                  id: 'A15._scale:_3',
                  content: 'screening.global.response.A15._scale_3',
                },
                {
                  id: 'A15._scale:_4',
                  content: 'screening.global.response.A15._scale_4',
                },
              ],
            },
            {
              id: 'A15',
              promptId: 'A15:_9',
              question: 'screening.global.question.A15',
              prompt: 'screening.global.question.A15_9',
              timeframe: 'screening.global.question.timeframe.week',
              responses: [
                {
                  id: 'A15._scale:_1',
                  content: 'screening.global.response.A15._scale_1',
                },
                {
                  id: 'A15._scale:_2',
                  content: 'screening.global.response.A15._scale_2',
                },
                {
                  id: 'A15._scale:_3',
                  content: 'screening.global.response.A15._scale_3',
                },
                {
                  id: 'A15._scale:_4',
                  content: 'screening.global.response.A15._scale_4',
                },
              ],
            },
            {
              id: 'A1',
              promptId: 'A1',
              question: 'screening.global.question.A1',
              timeframe: 'screening.global.question.timeframe.year',
              responses: [
                {
                  id: 'A1:_0',
                  content: 'screening.global.response.A1_0',
                },
                {
                  id: 'A1:_1',
                  content: 'screening.global.response.A1_1',
                },
                {
                  id: 'A1:_2',
                  content: 'screening.global.response.A1_2',
                },
                {
                  id: 'A1:_3',
                  content: 'screening.global.response.A1_3',
                },
                {
                  id: 'A1:_4',
                  content: 'screening.global.response.A1_4',
                },
                {
                  id: 'A1:_5',
                  content: 'screening.global.response.A1_5',
                },
                {
                  id: 'A1:_6',
                  content: 'screening.global.response.A1_6',
                },
                {
                  id: 'A1:_7',
                  content: 'screening.global.response.A1_7',
                },
                {
                  id: 'A1:_8',
                  content: 'screening.global.response.A1_8',
                },
                {
                  id: 'A1:_9',
                  content: 'screening.global.response.A1_9',
                },
                {
                  id: 'A1:_10',
                  content: 'screening.global.response.A1_10',
                },
              ],
            },
          ],
        },
        {
          title: 'screening.global.section.sb.title',
          content: 'screening.global.section.sb.intro',
          background: '/bg-hero-sb-1.png',
          backgroundStyles: 'md:bg-top lg:bg-center',
          id: 'sb',
          questions: [
            {
              id: 'A18',
              promptId: 'A18:_3',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_3',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
            {
              id: 'A18',
              promptId: 'A18:_11',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_11',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
            {
              id: 'A18',
              promptId: 'A18:_4',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_4',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
            {
              id: 'A18',
              promptId: 'A18:_7',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_7',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
            {
              id: 'A18',
              promptId: 'A18:_17',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_17',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
            {
              id: 'A18',
              promptId: 'A18:_6',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_6',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
            {
              id: 'A18',
              promptId: 'A18:_5',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_5',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
            {
              id: 'A18',
              promptId: 'A18:_1',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_1',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
            {
              id: 'A18',
              promptId: 'A18:_14',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_14',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
            {
              id: 'A18',
              promptId: 'A18:_2',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_2',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
            {
              id: 'A18',
              promptId: 'A18:_19',
              question: 'screening.global.question.A18',
              prompt: 'screening.global.question.A18_19',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A18_19._scale:_1',
                  content: 'screening.global.response.A18._scale_1',
                },
                {
                  id: 'A18_19._scale:_2',
                  content: 'screening.global.response.A18._scale_2',
                },
                {
                  id: 'A18_19._scale:_3',
                  content: 'screening.global.response.A18._scale_3',
                },
                {
                  id: 'A18_19._scale:_4',
                  content: 'screening.global.response.A18._scale_4',
                },
                {
                  id: 'A18_19._scale:_5',
                  content: 'screening.global.response.A18._scale_5',
                },
              ],
            },
          ],
        },
        {
          title: 'screening.global.section.ls.title',
          content: 'screening.global.section.ls.intro',
          background: '/bg-hero-ls-1.png',
          backgroundStyles: 'bg-[-91px_center] md:bg-top lg:bg-center',
          id: 'ls',
          questions: [
            {
              id: 'A25',
              promptId: 'A25:_1',
              question: 'screening.global.question.A25',
              prompt: 'screening.global.question.A25_1',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A25._scale:_4',
                  content: 'screening.global.response.A25._scale_4',
                },
                {
                  id: 'A25._scale:_3',
                  content: 'screening.global.response.A25._scale_3',
                },
                {
                  id: 'A25._scale:_2',
                  content: 'screening.global.response.A25._scale_2',
                },
                {
                  id: 'A25._scale:_1',
                  content: 'screening.global.response.A25._scale_1',
                },
              ],
            },
            {
              id: 'A25',
              promptId: 'A25:_2',
              question: 'screening.global.question.A25',
              prompt: 'screening.global.question.A25_2',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A25._scale:_4',
                  content: 'screening.global.response.A25._scale_4',
                },
                {
                  id: 'A25._scale:_3',
                  content: 'screening.global.response.A25._scale_3',
                },
                {
                  id: 'A25._scale:_2',
                  content: 'screening.global.response.A25._scale_2',
                },
                {
                  id: 'A25._scale:_1',
                  content: 'screening.global.response.A25._scale_1',
                },
              ],
            },
            {
              id: 'A25',
              promptId: 'A25:_3',
              question: 'screening.global.question.A25',
              prompt: 'screening.global.question.A25_3',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A25._scale:_4',
                  content: 'screening.global.response.A25._scale_4',
                },
                {
                  id: 'A25._scale:_3',
                  content: 'screening.global.response.A25._scale_3',
                },
                {
                  id: 'A25._scale:_2',
                  content: 'screening.global.response.A25._scale_2',
                },
                {
                  id: 'A25._scale:_1',
                  content: 'screening.global.response.A25._scale_1',
                },
              ],
            },
            {
              id: 'A25',
              promptId: 'A25:_4',
              question: 'screening.global.question.A25',
              prompt: 'screening.global.question.A25_4',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A25._scale:_4',
                  content: 'screening.global.response.A25._scale_4',
                },
                {
                  id: 'A25._scale:_3',
                  content: 'screening.global.response.A25._scale_3',
                },
                {
                  id: 'A25._scale:_2',
                  content: 'screening.global.response.A25._scale_2',
                },
                {
                  id: 'A25._scale:_1',
                  content: 'screening.global.response.A25._scale_1',
                },
              ],
            },
            {
              id: 'A25',
              promptId: 'A25:_5',
              question: 'screening.global.question.A25',
              prompt: 'screening.global.question.A25_5',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A25._scale:_4',
                  content: 'screening.global.response.A25._scale_4',
                },
                {
                  id: 'A25._scale:_3',
                  content: 'screening.global.response.A25._scale_3',
                },
                {
                  id: 'A25._scale:_2',
                  content: 'screening.global.response.A25._scale_2',
                },
                {
                  id: 'A25._scale:_1',
                  content: 'screening.global.response.A25._scale_1',
                },
              ],
            },
            {
              id: 'A26',
              promptId: 'A26',
              question: 'screening.global.question.A26',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'A26:_1',
                  content: 'screening.global.response.A26._1',
                },
                {
                  id: 'A26:_2',
                  content: 'screening.global.response.A26._2',
                },
              ],
            },
            {
              id: 'A27',
              promptId: 'A27',
              question: 'screening.global.question.A27',
              image: 'screening.global.question.image.A27',
              responses: [
                {
                  id: 'A27:_1',
                  content: 'screening.global.response.A27._1',
                },
                {
                  id: 'A27:_2',
                  content: 'screening.global.response.A27._2',
                },
                {
                  id: 'A27:_3',
                  content: 'screening.global.response.A27._3',
                },
                {
                  id: 'A27:_4',
                  content: 'screening.global.response.A27._4',
                },
                {
                  id: 'A27:_5',
                  content: 'screening.global.response.A27._5',
                },
              ],
            },
            {
              id: 'GRID_QA45',
              promptId: 'GRID_QA45:_2',
              question: 'screening.global.question.GRID_QA45',
              prompt: 'screening.global.question.GRID_QA45_2',
              timeframe: 'screening.global.question.timeframe.months',
              responses: [
                {
                  id: 'GRID_QA45.QA45:_5',
                  content: 'screening.global.response.GRID_QA45.QA45_5',
                },
                {
                  id: 'GRID_QA45.QA45:_4',
                  content: 'screening.global.response.GRID_QA45.QA45_4',
                },
                {
                  id: 'GRID_QA45.QA45:_3',
                  content: 'screening.global.response.GRID_QA45.QA45_3',
                },
                {
                  id: 'GRID_QA45.QA45:_2',
                  content: 'screening.global.response.GRID_QA45.QA45_2',
                },
                {
                  id: 'GRID_QA45.QA45:_1',
                  content: 'screening.global.response.GRID_QA45.QA45_1',
                },
              ],
            },
          ],
        },
      ],
    },
    'be-workplace': {
      title: 'screening.be-workplace.title',
      subtitle: 'screening.be-workplace.subtitle',
      intro: {
        background: '/bg-hero-work-management-1.png',
        title: 'screening.be-workplace.welcome.title',
        content: 'screening.be-workplace.welcome.intro',
        cta: 'screening.be-workplace.welcome.cta',
      },
      Sections: [
        {
          title: 'screening.be-workplace.section.work-content.title',
          content: 'screening.be-workplace.section.work-content.intro',
          background: '/bg-hero-work-content-1.jpeg',
          backgroundStyles: 'md:bg-top lg:bg-center',
          id: 'work-content',
          questions: [
            {
              id: 'WCONT1',
              promptId: 'WCONT1:_1',
              question: 'screening.be-workplace.question.WCONT1',
              prompt: 'screening.be-workplace.question.WCONT1_1',
              sectionTitleOverride: 'screening.be-workplace.section.work-content.meaning.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCONT1',
              promptId: 'WCONT1:_2',
              question: 'screening.be-workplace.question.WCONT1',
              prompt: 'screening.be-workplace.question.WCONT1_2',
              sectionTitleOverride: 'screening.be-workplace.section.work-content.meaning.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCONT1',
              promptId: 'WCONT1:_3',
              question: 'screening.be-workplace.question.WCONT1',
              prompt: 'screening.be-workplace.question.WCONT1_3',
              sectionTitleOverride: 'screening.be-workplace.section.work-content.meaning.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCONT1',
              promptId: 'WCONT1:_4',
              question: 'screening.be-workplace.question.WCONT1',
              prompt: 'screening.be-workplace.question.WCONT1_4',
              sectionTitleOverride: 'screening.be-workplace.section.work-content.workload.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCONT1',
              promptId: 'WCONT1:_5',
              question: 'screening.be-workplace.question.WCONT1',
              prompt: 'screening.be-workplace.question.WCONT1_5',
              sectionTitleOverride: 'screening.be-workplace.section.work-content.workload.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCONT1',
              promptId: 'WCONT1:_6',
              question: 'screening.be-workplace.question.WCONT1',
              prompt: 'screening.be-workplace.question.WCONT1_6',
              sectionTitleOverride: 'screening.be-workplace.section.work-content.workload.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCONT1',
              promptId: 'WCONT1:_7',
              question: 'screening.be-workplace.question.WCONT1',
              prompt: 'screening.be-workplace.question.WCONT1_7',
              sectionTitleOverride: 'screening.be-workplace.section.work-content.workload.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCONT1',
              promptId: 'WCONT1:_8',
              question: 'screening.be-workplace.question.WCONT1',
              prompt: 'screening.be-workplace.question.WCONT1_8',
              sectionTitleOverride: 'screening.be-workplace.section.work-content.workload.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCONT1',
              promptId: 'WCONT1:_9',
              question: 'screening.be-workplace.question.WCONT1',
              prompt: 'screening.be-workplace.question.WCONT1_9',
              sectionTitleOverride: 'screening.be-workplace.section.work-content.workload.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCONT1',
              promptId: 'WCONT1:_10',
              question: 'screening.be-workplace.question.WCONT1',
              prompt: 'screening.be-workplace.question.WCONT1_10',
              sectionTitleOverride: 'screening.be-workplace.section.work-content.workload.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
          ],
        },
        {
          title: 'screening.be-workplace.section.work-conditions.title',
          content: 'screening.be-workplace.section.work-conditions.intro',
          background: '/bg-hero-work-conditions-1.jpeg',
          backgroundStyles: 'md:bg-top lg:bg-center',
          id: 'work-conditions',
          questions: [
            {
              id: 'WCOND1',
              promptId: 'WCOND1:_1',
              question: 'screening.be-workplace.question.WCOND1',
              prompt: 'screening.be-workplace.question.WCOND1_1',
              sectionTitleOverride: 'screening.be-workplace.section.work-conditions.development.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCOND1',
              promptId: 'WCOND1:_2',
              question: 'screening.be-workplace.question.WCOND1',
              prompt: 'screening.be-workplace.question.WCOND1_2',
              sectionTitleOverride: 'screening.be-workplace.section.work-conditions.development.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCOND1',
              promptId: 'WCOND1:_3',
              question: 'screening.be-workplace.question.WCOND1',
              prompt: 'screening.be-workplace.question.WCOND1_3',
              sectionTitleOverride: 'screening.be-workplace.section.work-conditions.development.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCOND1',
              promptId: 'WCOND1:_4',
              question: 'screening.be-workplace.question.WCOND1',
              prompt: 'screening.be-workplace.question.WCOND1_4',
              sectionTitleOverride: 'screening.be-workplace.section.work-conditions.development.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCOND1',
              promptId: 'WCOND1:_5',
              question: 'screening.be-workplace.question.WCOND1',
              prompt: 'screening.be-workplace.question.WCOND1_5',
              sectionTitleOverride: 'screening.be-workplace.section.work-conditions.development.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCOND1',
              promptId: 'WCOND1:_6',
              question: 'screening.be-workplace.question.WCOND1',
              prompt: 'screening.be-workplace.question.WCOND1_6',
              sectionTitleOverride: 'screening.be-workplace.section.work-conditions.development.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCOND1',
              promptId: 'WCOND1:_7',
              question: 'screening.be-workplace.question.WCOND1',
              prompt: 'screening.be-workplace.question.WCOND1_7',
              sectionTitleOverride: 'screening.be-workplace.section.work-conditions.flexibility.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCOND1',
              promptId: 'WCOND1:_8',
              question: 'screening.be-workplace.question.WCOND1',
              prompt: 'screening.be-workplace.question.WCOND1_8',
              sectionTitleOverride: 'screening.be-workplace.section.work-conditions.flexibility.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCOND1',
              promptId: 'WCOND1:_9',
              question: 'screening.be-workplace.question.WCOND1',
              prompt: 'screening.be-workplace.question.WCOND1_9',
              sectionTitleOverride: 'screening.be-workplace.section.work-conditions.flexibility.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCOND1',
              promptId: 'WCOND1:_10',
              question: 'screening.be-workplace.question.WCOND1',
              prompt: 'screening.be-workplace.question.WCOND1_10',
              sectionTitleOverride: 'screening.be-workplace.section.work-conditions.flexibility.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCOND1',
              promptId: 'WCOND1:_11',
              question: 'screening.be-workplace.question.WCOND1',
              prompt: 'screening.be-workplace.question.WCOND1_11',
              sectionTitleOverride: 'screening.be-workplace.section.work-conditions.flexibility.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCOND1',
              promptId: 'WCOND1:_12',
              question: 'screening.be-workplace.question.WCOND1',
              prompt: 'screening.be-workplace.question.WCOND1_12',
              sectionTitleOverride: 'screening.be-workplace.section.work-conditions.flexibility.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCOND1',
              promptId: 'WCOND1:_13',
              question: 'screening.be-workplace.question.WCOND1',
              prompt: 'screening.be-workplace.question.WCOND1_13',
              sectionTitleOverride: 'screening.be-workplace.section.work-conditions.flexibility.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCOND1',
              promptId: 'WCOND1:_14',
              question: 'screening.be-workplace.question.WCOND1',
              prompt: 'screening.be-workplace.question.WCOND1_14',
              sectionTitleOverride: 'screening.be-workplace.section.work-conditions.security.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WCOND1',
              promptId: 'WCOND1:_15',
              question: 'screening.be-workplace.question.WCOND1',
              prompt: 'screening.be-workplace.question.WCOND1_15',
              sectionTitleOverride: 'screening.be-workplace.section.work-conditions.security.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
          ],
        },
        {
          title: 'screening.be-workplace.section.work-organisation.title',
          content: 'screening.be-workplace.section.work-organisation.intro',
          background: '/bg-hero-work-organisation-1.jpeg',
          backgroundStyles: 'md:bg-top lg:bg-center',
          id: 'work-organisation',
          questions: [
            {
              id: 'WORG1',
              promptId: 'WORG1:_1',
              question: 'screening.be-workplace.question.WORG1',
              prompt: 'screening.be-workplace.question.WORG1_1',
              sectionTitleOverride: 'screening.be-workplace.section.work-organisation.decisions.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WORG1',
              promptId: 'WORG1:_2',
              question: 'screening.be-workplace.question.WORG1',
              prompt: 'screening.be-workplace.question.WORG1_2',
              sectionTitleOverride: 'screening.be-workplace.section.work-organisation.decisions.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WORG1',
              promptId: 'WORG1:_3',
              question: 'screening.be-workplace.question.WORG1',
              prompt: 'screening.be-workplace.question.WORG1_3',
              sectionTitleOverride: 'screening.be-workplace.section.work-organisation.decisions.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WORG1',
              promptId: 'WORG1:_4',
              question: 'screening.be-workplace.question.WORG1',
              prompt: 'screening.be-workplace.question.WORG1_4',
              sectionTitleOverride: 'screening.be-workplace.section.work-organisation.management.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WORG1',
              promptId: 'WORG1:_5',
              question: 'screening.be-workplace.question.WORG1',
              prompt: 'screening.be-workplace.question.WORG1_5',
              sectionTitleOverride: 'screening.be-workplace.section.work-organisation.management.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WORG1',
              promptId: 'WORG1:_6',
              question: 'screening.be-workplace.question.WORG1',
              prompt: 'screening.be-workplace.question.WORG1_6',
              sectionTitleOverride: 'screening.be-workplace.section.work-organisation.management.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WORG1',
              promptId: 'WORG1:_7',
              question: 'screening.be-workplace.question.WORG1',
              prompt: 'screening.be-workplace.question.WORG1_7',
              sectionTitleOverride: 'screening.be-workplace.section.work-organisation.management.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WORG1',
              promptId: 'WORG1:_8',
              question: 'screening.be-workplace.question.WORG1',
              prompt: 'screening.be-workplace.question.WORG1_8',
              sectionTitleOverride: 'screening.be-workplace.section.work-organisation.management.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WORG1',
              promptId: 'WORG1:_9',
              question: 'screening.be-workplace.question.WORG1',
              prompt: 'screening.be-workplace.question.WORG1_9',
              sectionTitleOverride: 'screening.be-workplace.section.work-organisation.values.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WORG1',
              promptId: 'WORG1:_10',
              question: 'screening.be-workplace.question.WORG1',
              prompt: 'screening.be-workplace.question.WORG1_10',
              sectionTitleOverride: 'screening.be-workplace.section.work-organisation.values.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WORG1',
              promptId: 'WORG1:_11',
              question: 'screening.be-workplace.question.WORG1',
              prompt: 'screening.be-workplace.question.WORG1_11',
              sectionTitleOverride: 'screening.be-workplace.section.work-organisation.values.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WORG1',
              promptId: 'WORG1:_12',
              question: 'screening.be-workplace.question.WORG1',
              prompt: 'screening.be-workplace.question.WORG1_12',
              sectionTitleOverride: 'screening.be-workplace.section.work-organisation.values.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WORG1',
              promptId: 'WORG1:_13',
              question: 'screening.be-workplace.question.WORG1',
              prompt: 'screening.be-workplace.question.WORG1_13',
              sectionTitleOverride: 'screening.be-workplace.section.work-organisation.instructions.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WORG1',
              promptId: 'WORG1:_14',
              question: 'screening.be-workplace.question.WORG1',
              prompt: 'screening.be-workplace.question.WORG1_14',
              sectionTitleOverride: 'screening.be-workplace.section.work-organisation.instructions.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WORG1',
              promptId: 'WORG1:_15',
              question: 'screening.be-workplace.question.WORG1',
              prompt: 'screening.be-workplace.question.WORG1_15',
              sectionTitleOverride: 'screening.be-workplace.section.work-organisation.instructions.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WORG1',
              promptId: 'WORG1:_16',
              question: 'screening.be-workplace.question.WORG1',
              prompt: 'screening.be-workplace.question.WORG1_16',
              sectionTitleOverride: 'screening.be-workplace.section.work-organisation.role.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WORG1',
              promptId: 'WORG1:_17',
              question: 'screening.be-workplace.question.WORG1',
              prompt: 'screening.be-workplace.question.WORG1_17',
              sectionTitleOverride: 'screening.be-workplace.section.work-organisation.role.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WORG1',
              promptId: 'WORG1:_18',
              question: 'screening.be-workplace.question.WORG1',
              prompt: 'screening.be-workplace.question.WORG1_18',
              sectionTitleOverride: 'screening.be-workplace.section.work-organisation.role.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
          ],
        },
        {
          title: 'screening.be-workplace.section.work-relations.title',
          content: 'screening.be-workplace.section.work-relations.intro',
          background: '/bg-hero-work-relations-1.jpeg',
          backgroundStyles: 'md:bg-top lg:bg-center',
          id: 'work-relations',
          questions: [
            {
              id: 'WREL1',
              promptId: 'WREL1:_1',
              question: 'screening.be-workplace.question.WREL1',
              prompt: 'screening.be-workplace.question.WREL1_1',
              sectionTitleOverride: 'screening.be-workplace.section.work-relations.relationships.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WREL1',
              promptId: 'WREL1:_2',
              question: 'screening.be-workplace.question.WREL1',
              prompt: 'screening.be-workplace.question.WREL1_2',
              sectionTitleOverride: 'screening.be-workplace.section.work-relations.relationships.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WREL1',
              promptId: 'WREL1:_3',
              question: 'screening.be-workplace.question.WREL1',
              prompt: 'screening.be-workplace.question.WREL1_3',
              sectionTitleOverride: 'screening.be-workplace.section.work-relations.relationships.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WREL1',
              promptId: 'WREL1:_4',
              question: 'screening.be-workplace.question.WREL1',
              prompt: 'screening.be-workplace.question.WREL1_4',
              sectionTitleOverride: 'screening.be-workplace.section.work-relations.relationships.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WREL1',
              promptId: 'WREL1:_5',
              question: 'screening.be-workplace.question.WREL1',
              prompt: 'screening.be-workplace.question.WREL1_5',
              sectionTitleOverride: 'screening.be-workplace.section.work-relations.relationships.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WREL1',
              promptId: 'WREL1:_6',
              question: 'screening.be-workplace.question.WREL1',
              prompt: 'screening.be-workplace.question.WREL1_6',
              sectionTitleOverride: 'screening.be-workplace.section.work-relations.relationships.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WREL1',
              promptId: 'WREL1:_7',
              question: 'screening.be-workplace.question.WREL1',
              prompt: 'screening.be-workplace.question.WREL1_7',
              sectionTitleOverride: 'screening.be-workplace.section.work-relations.relationships.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WREL1',
              promptId: 'WREL1:_8',
              question: 'screening.be-workplace.question.WREL1',
              prompt: 'screening.be-workplace.question.WREL1_8',
              sectionTitleOverride: 'screening.be-workplace.section.work-relations.communication.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WREL1',
              promptId: 'WREL1:_9',
              question: 'screening.be-workplace.question.WREL1',
              prompt: 'screening.be-workplace.question.WREL1_9',
              sectionTitleOverride: 'screening.be-workplace.section.work-relations.communication.title',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WREL1',
              promptId: 'WREL1:_10',
              question: 'screening.be-workplace.question.WREL1',
              prompt: 'screening.be-workplace.question.WREL1_10',
              sectionTitleOverride: 'screening.be-workplace.section.work-relations.communication.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
          ],
        },
        {
          title: 'screening.be-workplace.section.work-environment.title',
          content: 'screening.be-workplace.section.work-environment.intro',
          background: '/bg-hero-work-environment-1.jpeg',
          backgroundStyles: 'md:bg-top lg:bg-center',
          id: 'work-environment',
          questions: [
            {
              id: 'WENV1',
              promptId: 'WENV1:_1',
              question: 'screening.be-workplace.question.WENV1',
              prompt: 'screening.be-workplace.question.WENV1_1',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WENV1',
              promptId: 'WENV1:_2',
              question: 'screening.be-workplace.question.WENV1',
              prompt: 'screening.be-workplace.question.WENV1_2',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WENV1',
              promptId: 'WENV1:_3',
              question: 'screening.be-workplace.question.WENV1',
              prompt: 'screening.be-workplace.question.WENV1_3',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WENV1',
              promptId: 'WENV1:_4',
              question: 'screening.be-workplace.question.WENV1',
              prompt: 'screening.be-workplace.question.WENV1_4',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WENV1',
              promptId: 'WENV1:_5',
              question: 'screening.be-workplace.question.WENV1',
              prompt: 'screening.be-workplace.question.WENV1_5',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WENV1',
              promptId: 'WENV1:_6',
              question: 'screening.be-workplace.question.WENV1',
              prompt: 'screening.be-workplace.question.WENV1_6',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WENV1',
              promptId: 'WENV1:_7',
              question: 'screening.be-workplace.question.WENV1',
              prompt: 'screening.be-workplace.question.WENV1_7',
              responses: [
                {
                  id: 'WP._scale:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WENV1',
              promptId: 'WENV1:_8',
              question: 'screening.be-workplace.question.WENV1',
              prompt: 'screening.be-workplace.question.WENV1_8',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WENV1',
              promptId: 'WENV1:_9',
              question: 'screening.be-workplace.question.WENV1',
              prompt: 'screening.be-workplace.question.WENV1_9',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
            {
              id: 'WENV1',
              promptId: 'WENV1:_10',
              question: 'screening.be-workplace.question.WENV1',
              prompt: 'screening.be-workplace.question.WENV1_10',
              sectionTitleOverride: 'screening.be-workplace.section.work-environment.wellbeing.title',
              responses: [
                {
                  id: 'WP._scale_inverse:_1',
                  content: 'screening.be-workplace.response.WP._scale_1',
                },
                {
                  id: 'WP._scale_inverse:_2',
                  content: 'screening.be-workplace.response.WP._scale_2',
                },
                {
                  id: 'WP._scale_inverse:_3',
                  content: 'screening.be-workplace.response.WP._scale_3',
                },
                {
                  id: 'WP._scale_inverse:_4',
                  content: 'screening.be-workplace.response.WP._scale_4',
                },
                {
                  id: 'WP._scale_inverse:_5',
                  content: 'screening.be-workplace.response.WP._scale_5',
                },
              ],
            },
          ],
        },
      ],
    },
  },
};

export default Config;
